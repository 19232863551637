<template>
    <div>
		<page-title :heading=heading :subheading=subheading></page-title>


        <a-row :gutter="24" type="flex" align="stretch" class="py-0" style="margin-top: 40px;">
	
            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-24 py-0">
                <a-card :bordered="false" class="bg-gray-100 header-solid h-full py-0" style="padding-top: 0px; padding-bottom: 0px;">
                    <a-form-model layout="horizontal" @submit.prevent="getStatistics" class="py-0 my-0">
                        <!-- <a-row class="py-0">
                            <a-col :span="24" :sm="24" :md="24" :lg="24" :xl="24" class=""> -->
                                <!-- <a-form-item label="From"> -->
                                    <label class="mr-2"> Start Date: </label>
                                    <a-date-picker 
                                        class="mr-5"
                                        v-model="statistics.startDate"
                                        :disabled-date="disabledStartDate"
                                        format="DD MMM YYYY" />
                                <!-- </a-form-item> -->
                            <!-- </a-col>
                            
                            <a-col :span="24" :sm="5" :md="5" :lg="5" :xl="5" class=""> -->
                                <!-- <a-form-item label="To"> -->
                                    <label class="mr-2 mt-3"> End Date: </label>
                                    <a-date-picker 
                                        class="mr-5"
                                        v-model="statistics.endDate"
                                        :disabled-date="disabledEndDate"
                                        format="DD MMM YYYY" />
                                <!-- </a-form-item> -->
                            <!-- </a-col>
                            
                            <a-col :span="24" :sm="5" :md="5" :lg="5" :xl="5" class=""> -->
                                <!-- <a-form-item> -->
                                    <a-button id="filterBtn" type="primary" class="" html-type="submit">
                                        Filter
                                    </a-button>
                                <!-- </a-form-item> -->
                            <!-- </a-col>
                        
                        </a-row> -->
                    </a-form-model>
                </a-card>
            </a-col>
            
        </a-row>

        <div class="mt-4">
            <a-row :gutter="[24, 24]" class="pt-4">

                <a-col :span="24" :md="6">
                    <div class="card mb-3 widget-content">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Products</div>
                                <div class="widget-subheading">Products available</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-primary"><span>{{statistics.medicineNum}}</span></div>
                            </div>
                        </div>
                    </div>
                </a-col>
                

				<a-col :span="24" :md="6">
                    <div class="card mb-3 widget-content">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Sales</div>
                                <div class="widget-subheading">Sales in Tshs</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-primary"><span>{{ parseInt(statistics.totalSales).toLocaleString() + '/=' }}</span></div>
                            </div>
                        </div>
                    </div>
                </a-col>

				<a-col :span="24" :md="6">
                    <div class="card mb-3 widget-content">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Expenses</div>
                                <div class="widget-subheading">Expenses in Tshs</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-primary"><span>{{ parseInt(statistics.totalExpenses).toLocaleString() + '/=' }}</span></div>
                            </div>
                        </div>
                    </div>
                </a-col>

				<a-col :span="24" :md="6">
                    <div class="card mb-3 widget-content">
                        <div class="widget-content-wrapper">
                            <div class="widget-content-left">
                                <div class="widget-heading">Total Users</div>
                                <div class="widget-subheading">No. of users</div>
                            </div>
                            <div class="widget-content-right">
                                <div class="widget-numbers text-primary"><span>{{ statistics.userNum }}</span></div>
                            </div>
                        </div>
                    </div>
                </a-col>

				

			</a-row>
        </div>
        

        <a-row :gutter="[24, 24]" class="pt-4">

            <a-col :span="24" :md="12">
                <a-card class="main-card mb-3">
                    <h6>Daily Sale Report</h6>
                    <LineChart v-if="!statistics.loading && statistics.dailySales != null && statistics.dailySales.length > 0"/>

                    <div v-if="statistics.loading">
                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 30vh">
                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                                <a-spin />
                            </a-col>
                        </a-row>
                    </div>

                </a-card>
            </a-col>

            <a-col :span="24" :md="12">
                <a-card class="main-card mb-3">
                    <h6>Monthly Sale Report</h6>
                    <BarChart v-if="!statistics.loading && statistics.monthlySales != null && statistics.monthlySales.length > 0"/>

                    <div v-if="statistics.loading">
                        <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 30vh">
                            <a-col :span="24" :md="12" :lg="12" :xl="{span: 6, offset: 0}" class="text-center  justify-items-center">
                                <a-spin />
                            </a-col>
                        </a-row>
                    </div>

                </a-card>
            </a-col>

        </a-row>


        <a-row :gutter="[24, 24]" class="pt-4">

            
            <a-col :span="24" :md="12">
                <a-card title="Top Selling Products" class="main-card mb-3">
                    <a-table class="mt-20 pb-2"  
							size="middle"
							:bordered="false"
							:columns="topSellingMedicinesColumns" 
							:data-source="statistics.topSellingMedicines" 
							:rowKey="record => record.uuid"
							:loading="statistics.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ index + 1 }}
								</template>
						</a-table>
                </a-card>
            </a-col>

            <a-col :span="24" :md="12">
                <a-card title="Low Quantity Products" class="main-card mb-3">
                    <a-table class="mt-20 pb-2"  
							size="middle"
							:bordered="false"
							:columns="lowQuantityMedicineCountColumns" 
							:data-source="statistics.lowQuantityMedicineCount" 
							:rowKey="record => record.uuid"
							:loading="statistics.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ index + 1 }}
								</template>
						</a-table>
                </a-card>
            </a-col>

        </a-row>


        <a-row :gutter="[24, 24]" class="pt-4">

            
            <a-col :span="24" :md="12">
                <a-card title="Expiring Products" class="main-card mb-3">
                    <a-table class="mt-20 pb-2"  
							size="middle"
							:bordered="false"
							:columns="expiringDrugColumns" 
							:data-source="statistics.expiringDrugs" 
							:rowKey="record => record.uuid"
							:loading="statistics.loading" 
							:pagination="false">

								<template slot="s_no" slot-scope="s_no, record, index">
									{{ index + 1 }}
								</template>

                                <template slot="remaining_days" slot-scope="remaining_days">
									{{ remaining_days > 0 ? remaining_days : "EXPIRED"}}
								</template>

						</a-table>
                </a-card>
            </a-col>

            <a-col :span="24" :md="12">
                
            </a-col>

        </a-row>


        

        
        
		
		<a-card :bordered="false" class="header-solid px-5" :bodyStyle="{padding: 0, paddingTop: '16px'}">
			
			

		</a-card>

        
    </div>
  </template>
  
  <script>
  
    import PageTitle from "../../Layout/Components/PageTitle.vue";
    import BarChart from './Chartjs/Bar'
    // import BarChart from './BarChart.vue'
    import LineChart from './Chartjs/Line'
    import dayjs from 'dayjs';

    const topSellingMedicinesColumns = [
		{
            // title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Type',
			dataIndex: 'type',
		},
		{
			title: 'Quantity Sold',
			dataIndex: 'quantity_sold',
			scopedSlots: { customRender: 'quantity_sold' },
		},
        {
			title: 'Total Amount',
			dataIndex: 'total_sale_amount',
			scopedSlots: { customRender: 'total_sale_amount' },
		},
	];


    const lowQuantityMedicineCountColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'Name',
			dataIndex: 'name',
		},
		{
			title: 'Type',
			dataIndex: 'type',
		},
		{
			title: 'Quantity Sold',
			dataIndex: 'quantitysold',
			scopedSlots: { customRender: 'quantitySold' },
		},
        {
			title: 'Quantity Left',
			dataIndex: 'quantityleft',
			scopedSlots: { customRender: 'quantityleft' },
		},
	];


    const expiringDrugColumns = [
		{
            title: 'S/N',
            dataIndex: 's_no',
			scopedSlots: { customRender: 's_no' },
        },
		{
			title: 'Name',
			dataIndex: 'drug_name',
		},
		{
			title: 'Batch',
			dataIndex: 'batch_number',
		},
		// {
		// 	title: 'Type',
		// 	dataIndex: 'drug_type',
		// },
		{
			title: 'Quantity',
			dataIndex: 'quantity_left',
		},
        {
			title: 'Remaining Days',
			dataIndex: 'remaining_days',
			scopedSlots: { customRender: 'remaining_days' },
		},
	];
    
    export default {
		components: {
			PageTitle,
            BarChart,
            LineChart
		},
		data() {
			return {
            heading: 'Settings',
            subheading: 'Medicine Types',
            icon: 'pe-7s-drawer icon-gradient bg-happy-itmeo',
    
            topSellingMedicinesFields: ['name', 'type', 'quantitySold', 'totalSaleAmount' ],
            lowQuantityMedicineCountFields: ['name', 'type', 'quantityleft', 'quantitysold' ],

            topSellingMedicinesColumns,
            lowQuantityMedicineCountColumns,
            expiringDrugColumns,

            labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],

            userDetails: {
                user: null,
                company: null
            },

            filter: {
                startDate: null,
                endDate: null,
                loading: false,
            },

            statistics: {
                loading: false,
                startDate: null,
                endDate: null,
                userNum: 0,
                medicineNum: 0,
                totalSales: 0,
                totalExpenses: 0,
                topSellingMedicines: [],
                lowQuantityMedicineCount: [],
                expiringDrugs: [],
                dailySales: [],
                monthlySales: [],
            },  



            notify: {
                text: null,
                type: null,
            },
        }
		},
		mounted() {
            this.defaultDate()
            this.getUserDetails();
            this.getStatistics();
		},
		methods: {

            formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },
            
            defaultDate() {
				this.statistics.startDate = dayjs(this.$Moment(this.getLastWeeksDate()).format("YYYY-MM-DD"), "YYYY-MM-DD");
				this.statistics.endDate = dayjs(this.$Moment(new Date()).format("YYYY-MM-DD"), "YYYY-MM-DD");

			},

            getLastWeeksDate() {
				const now = new Date();

				return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
			},

			disabledStartDate(current) {
				// Can not select days before today and today
				return current > this.statistics.endDate;
			},

			disabledEndDate(current) {
				// Can not select days before today and today
				return current && (current < this.statistics.startDate || current > dayjs().endOf('day'));
			},

            async getUserDetails() {
                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                if(userDetails != null) {
                    this.userDetails.user = userDetails.user;
                    this.userDetails.company = userDetails.company;
                }else {
                    this.$router.push(`/`);
                }
            },


            async getStatistics() {
                this.statistics.loading = true;

                let userDetails = await JSON.parse(localStorage.getItem("pharmUser"));

                let { startDate, endDate } = this.statistics;

				startDate = this.$Moment(startDate.$d != null ? startDate.$d : startDate._d).format("YYYY-MM-DD")
				endDate = this.$Moment(endDate.$d != null ? endDate.$d : endDate._d).format("YYYY-MM-DD")


                let url = `${this.$BACKEND_URL}/reports/statistics/filter/${userDetails.company.uuid}?startDate=${startDate}&endDate=${endDate}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        // eslint-disable-next-line no-console
                        // console.log(response.data)
                        this.statistics.userNum = response.data.userNum;
                        this.statistics.medicineNum = response.data.medicineNum;
                        this.statistics.totalSales = response.data.totalSales != null ? response.data.totalSales : 0;
                        this.statistics.topSellingMedicines = response.data.topSellingDrugs;
                        this.statistics.lowQuantityMedicineCount = response.data.lowQuantityDrugCount;
                        this.statistics.expiringDrugs = response.data.expiringDrugs;
                        this.statistics.totalExpenses = response.data.total_expenses;

                        let monthlySaleLabels = response.data.monthlySales.map(record => this.getMonthName(record.month))
                        let monthlySaleValues = response.data.monthlySales.map(record => record.total_amount)

                        await localStorage.setItem("monthlySaleLabels", JSON.stringify(monthlySaleLabels))
                        await localStorage.setItem("monthlySaleValues", JSON.stringify(monthlySaleValues))

                        let dailySaleLabels = response.data.dailySales.map(record => this.$Moment(record.sale_date).format('DD MMM YYYY'))
                        let dailySaleValues = response.data.dailySales.map(record => record.total_amount)

                        await localStorage.setItem("dailySaleLabels", JSON.stringify(dailySaleLabels.reverse()))
                        await localStorage.setItem("dailySaleValues", JSON.stringify(dailySaleValues.reverse()))

                        this.statistics.dailySales = response.data.dailySales;
                        this.statistics.monthlySales = response.data.monthlySales;
                    }
                    this.statistics.loading = false;
                }).catch((error) => {

                    this.statistics.loading = false;

                    if(error.response != null && error.response.status == 401) {
                        this.$router.push(`/`);
                    }

                    // eslint-disable-next-line no-console
                    console.log(error)

                    this.notify.text = error.response != null &&  error.response.data != null ? error.response.data.message : "Something went wrong, please try again!";
                    this.notify.type = "error"

                    this.$notify(this.notify)

                });
            },


            getMonthName(num) {
                switch (num) {
                    case 1:
                        return 'January';
                    case 2:
                        return 'February';
                    case 3:
                        return 'March';
                    case 4:
                        return 'April';
                    case 5:
                        return 'May';
                    case 6:
                        return 'June';
                    case 7:
                        return 'July';
                    case 8:
                        return 'August';
                    case 9:
                        return 'September';
                    case 10:
                        return 'October';
                    case 11:
                        return 'November';
                    case 12:
                        return 'December';
                    default:
                        return "January";
                }
            }

		},


    }
  </script>
  
  <style lang="scss">
  
  #textBtn, #textBtn:hover {
      border: none !important;
  }

  .widget-numbers {
    font-size: 20px !important;
  }
</style>